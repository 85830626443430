import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calTotal } from "../../../../../utils/giftshop";
import { RootState } from "../../../../../store";
import {
  EncryptionKeyResponse,
  PAYMENT_TYPE,
} from "../../../../../models/payment";
import CreditCard, {
  ICardRef,
} from "../../../../../components/TicketBooking/Payment/CreditCard";
import {
  getCreditCardEncryptionKey,
  getPaymentMethod,
  giftCardCompleteOrderApi,
  giftCardMakePaymentApi,
  giftCardOrderContinueAPI,
  refundGiftCardPaymentApi,
  sendMailApi,
} from "../../../../../services/payment";
import {
  CAPTCHA_SITE_KEY,
  COUNTRY,
  GIFT_CARD_CINEMA_ID,
  PAYMENT_PROVIDERS,
} from "../../../../../constants/app";
import { ICartData } from "../../../../../store/giftshop/type";
import { GIFT_CARD_CATEGORY_TYPE, GiftType, PROMO_TYPE  } from "../../../../../models/giftcard";
import { updateGiftCardBookingIdAction } from "../../../../../store/giftshop/action";
import { toast } from "react-toastify";
import { TICKET_FLOW } from "../../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../../models/tickets";
import { setModal, toggleTicketModal } from "../../../../../store/ticketBooking/action";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG, URLS } from "../../../../../constants/url";
import {
  GIFTSHOP_MAKE_PAYMENT_REQUEST_TYPE,
  GIFT_SHOP_REFUND_REQUEST_TYPE,
  GIFT_SHOP_TOKEN_PAYMENT_REQUEST_TYPE,
} from "../../../../../constants/payment";
import { encryptData } from "../../../../../utils/helper";
import StripePayment from "../../../../../components/TicketBooking/Payment/Stripe";
import GiftCard from "../../../../../components/TicketBooking/Payment/GiftCard";
import ReCAPTCHA from "react-google-recaptcha";
import "./payment.scss";
import GPay from "../../../../../components/TicketBooking/Payment/GPay";
import ApplePay from "../../../../../components/TicketBooking/Payment/ApplePay";
import Click2pay from "../../../../../components/TicketBooking/Payment/Click2pay";
import { setContentModal, toggleContentModal } from "../../../../../store/application/action";

let sendEmail = "";
let click2payCard: any = null;

interface IGiftshopPaymentProps {}

const GiftshopPayment: FC<IGiftshopPaymentProps> = () => {
  const {
    cart,
    orderResponse,
    userDetails,
    memberDetail,
    countryId,
    isLoggedIn,
    settings,
    currentCinema,
    modalData
  } = useSelector((state: RootState) => ({
    cart: state.giftCardReducer.cart,
    orderResponse: state.giftCardReducer.orderResponse,
    userDetails: state.authReducer.userDetails,
    memberDetail: state.authReducer.memberDetail,
    countryId: state.applicationReducer.countryId as COUNTRY,
    isLoggedIn: state.authReducer.isLoggedIn,
    settings: state.applicationReducer.settings,
    currentCinema: state.applicationReducer.currentCinema,
    modalData: state.ticketBookingReducer.modal,
  }));

  const dispatch = useDispatch();

  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.CARD);
  const [errorMessage, setErrorMessage] = useState("");
  const [googlePaySupport, setGooglePaySupport] = useState(false);
  const cardRef = useRef<ICardRef>(null);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [emailAddress, setEmailAddress] = useState(userDetails?.email);
  const [errorEmailAddress, setErrorEmailAddress] = useState("");
  const [showWallet, setShowWallet] = useState("");
  const [cartData, setCartData] = useState<{
    deliveryFee: number;
    deliveryQty: number;
    total: number;
    deliveryCharge: number;
  }>({ deliveryFee: 0, deliveryQty: 0, total: 0, deliveryCharge: 0 });

  useEffect(()=>{
    if(settings){
      setPaymentType(settings.enableClick2pay?PAYMENT_TYPE.CLICK_TO_PAY:PAYMENT_TYPE.CARD);
    }
  },[settings])

  useEffect(() => {
    if (
      countryId !== COUNTRY.NZ &&
      !/^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ) {
      setShowWallet(PAYMENT_TYPE.GOOGLE_PAY);
    } else if (
      countryId !== COUNTRY.NZ &&
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ) {
      const applepaySession = (window as any).ApplePaySession;
      if (applepaySession) {
        if (applepaySession.canMakePayments()) {
          setShowWallet(PAYMENT_TYPE.APPLE_PAY);
        }
      }
    }
  }, [countryId]);

  useEffect(() => {
    setCartData(calTotal(cart, countryId));
  }, [cart]);

  const totalAmount = useMemo(() => {
    const { total } = calTotal(cart, countryId);
    return total;
  }, [cart]);

  const promoAmount = useMemo(() => {
    return cart.reduce((total: number, v: any) => {
      return (total =
        total + (v.giftcardType === GiftType.ecard && (v.promoType === PROMO_TYPE.SEPARATE || v.promoType === PROMO_TYPE.EXISTING)? v.promo.amount : 0));
    }, 0);
  }, [cart]);

  const makeGiftCardPayment = async (
    processPayload: any,
    ptype: PAYMENT_TYPE,
    key: string
  ) => {
    //Make payment

    let amount = totalAmount;
    if (getPaymentMethod(countryId) !== PAYMENT_PROVIDERS.STRIPE) {
      amount = +(+amount * 100).toFixed();
    }

    sendEmail = processPayload?.cardDetails?.email
      ? processPayload.cardDetails.email
      : ptype === PAYMENT_TYPE.APPLE_PAY || ptype === PAYMENT_TYPE.GOOGLE_PAY
      ? emailAddress
      : userDetails?.email!;

    const makePaymentPayload: any = {
      msgId: orderResponse?.userSessionId!,
      cinemaId: GIFT_CARD_CINEMA_ID[countryId],
      amount: amount,
      transactionReference: `invoice${orderResponse?.userSessionId!}`,
      requestType: GIFTSHOP_MAKE_PAYMENT_REQUEST_TYPE[countryId],
      saleId: orderResponse?.userSessionId!,
      email: sendEmail,
      countryId: countryId,
      paymentSessionId: orderResponse?.userSessionId!,
      memberRefId: userDetails?.result?.memberRefId || "",
    };

    //Checking payment type - new card, saved card, apple pay or google pay
    if (
      (processPayload.cardDetails && processPayload.action === "newCard") ||
      ptype === PAYMENT_TYPE.APPLE_PAY ||
      ptype === PAYMENT_TYPE.GOOGLE_PAY
    ) {
      const encryptKey = key + orderResponse?.userSessionId!;

      const encryptedData = encryptData(
        JSON.stringify(processPayload.cardDetails),
        encryptKey
      );
      makePaymentPayload.data = encryptedData;
      makePaymentPayload.saveCreditCard = processPayload?.saveCard;
    } else if (
      processPayload.cardDetails &&
      processPayload.action === "savedCard"
    ) {
      makePaymentPayload.tokenValue = processPayload.cardDetails.token;
      makePaymentPayload.requestType =
        GIFT_SHOP_TOKEN_PAYMENT_REQUEST_TYPE[countryId];
      
      if(ptype === PAYMENT_TYPE.CLICK_TO_PAY){
        makePaymentPayload.paymentMethodType = processPayload.cardDetails.paymentMethodType
      }  
    }

    if (ptype === PAYMENT_TYPE.APPLE_PAY || ptype === PAYMENT_TYPE.GOOGLE_PAY) {
      makePaymentPayload.googleApplePay = true;
      makePaymentPayload.id = processPayload.paymentIntentId;
    }
    if (countryId === COUNTRY.ANG || countryId === COUNTRY.AUS || countryId === COUNTRY.STA) {
      makePaymentPayload.deviceId = (document.getElementById('pmnts_id') as any)?.value;
    }
    return await giftCardMakePaymentApi(makePaymentPayload);
  };

  const completeOrder = async ({ cardType }: any) => {
    let amt_cent = +totalAmount * 100;
    let amt = amt_cent.toFixed();

    let vistaTotal = cart
      .filter((item: ICartData) => item.giftcardType == GiftType.ecard)
      .reduce(
        (a: number, b: ICartData) =>
          a + b.quantity * (!b.isVariablePriceItem ? b.price : b.giftamount),
        0
      );
    let vistaAmt = (+vistaTotal+promoAmount) * 100;

    let completeOrderPayload: any = {
      requestType: "completeOrder",
      userSessionId: orderResponse?.userSessionId!,
      firstName: userDetails?.firstName?userDetails?.firstName:'',
      lastName: userDetails?.lastName?userDetails?.lastName:'',
      email: sendEmail?sendEmail:'',
      phoneNumber: userDetails?.phoneNumber?userDetails?.phoneNumber:'',
      paymentCardType: cardType,
      paymentAmountInCents: parseInt(amt),
      saleId: orderResponse?.userSessionId!,
      paymentSessionId: orderResponse?.userSessionId!,
      loyaltyCardNumber:
        memberDetail &&
        memberDetail?.LoyaltyMember &&
        memberDetail?.LoyaltyMember?.CardNumber
          ? memberDetail.LoyaltyMember.CardNumber
          : "",
      countryId: countryId,
      eGiftAmount: parseInt(vistaAmt.toFixed()),
      paidamount: totalAmount,
      theatreId: currentCinema?.slug
    };

    if(paymentType === PAYMENT_TYPE.CLICK_TO_PAY){
      completeOrderPayload['click2payCardDetails'] = click2payCard;
    }
    return await giftCardCompleteOrderApi(completeOrderPayload);
  };

  const refundCard = async (
    txnID: string,
    transactionReference: string,
    amount: number
  ) => {
    const refundDetails = {
      cinemaId: GIFT_CARD_CINEMA_ID[countryId],
      amount: amount,
      countryId: countryId,
      txnType: 4,
      requestType: GIFT_SHOP_REFUND_REQUEST_TYPE[countryId],
      paymentMethod: "ccard",
      txnID: txnID,
      transactionReference: transactionReference,
      saleId: orderResponse?.userSessionId!,
    };
    return await refundGiftCardPaymentApi(refundDetails);
  };

  const sendConfirmationMails = async () => {
    let payloadDataConfirmation = {
      id: orderResponse?.userSessionId!,
      templateName: "Giftcard order confirmation",
      email: sendEmail,
      countryId: countryId,
    };
    await sendMailApi(payloadDataConfirmation);
    let payloadDataCard = {
      countryId: countryId,
      id: orderResponse?.userSessionId!,
      templateName: "GiftcardShop send",
    };
    await sendMailApi(payloadDataCard);
    // Sending mail if physical cards purchased
    const phyCards = cart.filter(
      (item: ICartData) => item.giftcardType != GiftType.ecard
    );
    if (phyCards.length > 0 && modalData?.data !== 'pos') {
      let physicalCard = {
        id: orderResponse?.userSessionId!,
        templateName: "Physical Card Booking",
        countryId: countryId,
      };
      await sendMailApi(physicalCard);
    }
    return true;
  };

  const paymentError = () => {
    const modal =
      TICKET_FLOW[TICKET_FLOW_TYPES.GIFTSHOP][MODAL_TYPE.GIFTSHOP_ERROR];
    dispatch(
      setModal({
        ...modal,
        type: TICKET_FLOW_TYPES.GIFTSHOP,
        data: modalData?.data
      })
    );
  };

  const processPayment = async (processPayload: any, ptype: PAYMENT_TYPE) => {
    getCreditCardEncryptionKey(
      {
        countryId: countryId,
        saleId: orderResponse?.userSessionId!,
        requestType: "giftcard",
      },
      async (data: EncryptionKeyResponse | null) => {
        if (data) {
          //orderContinue api
          await giftCardOrderContinueAPI({
            userSessionId: orderResponse?.userSessionId!,
            requestType: "orderContinue",
            saleId: orderResponse?.userSessionId!,
            countryId: countryId,
          });
          const makePaymentResponse: any = await makeGiftCardPayment(
            processPayload,
            ptype,
            data.data.key
          );
          if (makePaymentResponse.status) {
            const completeOrderResponse: any = await completeOrder({
              cardType: "ccard",
            });

            if (completeOrderResponse.status && completeOrderResponse.VistaBookingId) {
              dispatch(
                updateGiftCardBookingIdAction(
                  completeOrderResponse.VistaBookingId
                )
              );
              const result: any = await sendConfirmationMails();
              if (result) {
                const modal =
                  TICKET_FLOW[TICKET_FLOW_TYPES.GIFTSHOP][
                    MODAL_TYPE.GIFTSHOP_CONFIRMATION
                  ];
                dispatch(
                  setModal({
                    ...modal,
                    type: TICKET_FLOW_TYPES.GIFTSHOP,
                    data: modalData?.data
                  })
                );
              }
            } else {
              const result = await refundCard(
                makePaymentResponse.data.txnID,
                makePaymentResponse.data.transactionReference,
                makePaymentResponse.data.amount
              );
              if (result) {
                paymentError();
              }
            }
          } else {
            paymentError();
          }
        }
      }
    );
  };

  const onCheckTerms = (event: any) => {
    const {
      target: { checked },
    } = event;
    setIsTermsChecked(checked);
  };
  
  const onCardPayment = async () => {
    const cardDetails: any = cardRef?.current?.submit();
    if (!cardDetails) {
      return;
    }
    const { card, action, saveCard } = cardDetails;
    if (card && !isTermsChecked && !isLoggedIn) {
      setErrorMessage("Please agree Terms and conditions");
      return;
    }

    if (!isCaptchaChecked) {
      setErrorMessage("Verification Code is required");
      return;
    }

    if (action === "newCard") {
      processPayment(
        { cardDetails: card, action, saveCard },
        PAYMENT_TYPE.CARD
      );
    } else if (action === "savedCard") {
      processPayment({ cardDetails: card, action }, PAYMENT_TYPE.CARD);
    }
  };

  const onPurchase = () => {
    switch (paymentType) {
      case PAYMENT_TYPE.CARD:
        onCardPayment();
        break;
    }
  };
  const getTerms = () => {
    // API_CALLER.get(pagecontent, {
    //   params: {
    //     countryId: `${COUNTRYID}`,
    //     pageName: "etixterms",
    //   },
    // }).then(async (response: any) => {
    //   if (response.status === 200) {
    //     navigatorUtil.push("PdfViewer", {
    //       pageContent: response.data.data,
    //       pageType: "Terms",
    //       pageTitle: "e-Tix Terms and conditions",
    //     });
    //   }
    // });
  };

  const toggleTab = (type: PAYMENT_TYPE) => {
    setErrorMessage("");
    setErrorEmailAddress("");
    setPaymentType(type);
  };

  const onChangeCaptcha = (event: any) => {
    if (event) {
      setIsCaptchaChecked(true);
      setErrorMessage("");
    } else {
      setIsCaptchaChecked(false);
      setErrorMessage("Verification Code is required");
    }
  };

  const checkValid = () => {
    if (paymentType === PAYMENT_TYPE.APPLE_PAY || paymentType === PAYMENT_TYPE.GOOGLE_PAY) {
      if (!emailAddress) {
        setErrorEmailAddress("Email is required");
        return;
      } else {
        setErrorEmailAddress("");
      }
    } else {
      setEmailAddress("");
      setErrorEmailAddress("");
    }
    if (!isTermsChecked) {
      setErrorMessage("Please agree Terms and conditions");
      return false;
    } else if (!isCaptchaChecked) {
      setErrorMessage("Verification Code is required");
      return false;
    }
  };

  const isValid = useMemo(() => {
    if (paymentType === PAYMENT_TYPE.GOOGLE_PAY) {
      if (!isTermsChecked) {
        return false;
      } else if (!isCaptchaChecked) {
        return false;
      }
    }
    setErrorMessage("");
    setErrorMessage("");
    return true;
  }, [isTermsChecked, isCaptchaChecked, paymentType]);

  const onError = (errorMsg?: string) => {
    const modal =
      TICKET_FLOW[TICKET_FLOW_TYPES.GIFTSHOP][MODAL_TYPE.GIFTSHOP_ERROR];
    dispatch(
      setModal({
        ...modal,
        type: MODAL_TYPE.GIFTSHOP_ERROR,
        data: modalData?.data
      })
    );
  };


  const showContent = useMemo(()=>{
    return paymentType === PAYMENT_TYPE.CLICK_TO_PAY?false:true
  },[paymentType])

  const click2payfunc = (data: any)=>{
    const { cardExpiry, cardHolder, cardNumber, cardSubcategory, cardType, token } = data
    click2payCard = {cardExpiry, cardHolder, cardNumber, cardSubcategory, cardType,
        paymentMethodType: 'click2pay'
    };
    processPayment({ cardDetails:  data, action: "savedCard" }, PAYMENT_TYPE.CLICK_TO_PAY);
  }

  const onCancelTicket = () => {
    dispatch(toggleTicketModal(false));
  };

  const c2pBanner = useMemo(()=>{
    switch(countryId){
      case COUNTRY.AUS:
        return 'c2p_reading_banner.png';
      case COUNTRY.ANG:
        return 'c2p_angelika_banner.png';  
      default:
        return ''  
    }
  },[countryId])

  const openTerms = () => {
    dispatch(
      setContentModal({
        type: "Gift Shop Terms",
      })
    );
    dispatch(toggleContentModal(true));
  };

  return (
    <div className="row giftcard-payment-wrapper">
        {c2pBanner?<div className="c2p_banner_giftshop_wrapper">
        <img className="c2p_giftshop_banner" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + c2pBanner} />
      </div>:null}
      <div className="col-12 auth-wrapper px-4">
        <div className="row">
          <div className="col-md-12">
            <div className="row no-gutters pay-method p-0">
              <div className="col-12 col-md-12">
                <div className="form_page pay_heading">
                  <h4 className="mb-4">Payment Details</h4>
                  <div className="pay_method_icons">
                    <ul className="nav nav-tabs">
                    {settings?.enableClick2pay?<li
                        className={`nav-item icons ${
                          paymentType === PAYMENT_TYPE.CLICK_TO_PAY ? "active" : ""
                        }`}
                        onClick={() => toggleTab(PAYMENT_TYPE.CLICK_TO_PAY)}
                      >
                        <a
                          className="nav-link active"
                          href="javascript:void(0);"
                        >
                          <span className="credit_card ">Click to Pay</span>
                        </a>
                      </li>: null}
                      <li
                        className={`nav-item icons ${
                          paymentType === PAYMENT_TYPE.CARD ? "active" : ""
                        }`}
                        onClick={() => toggleTab(PAYMENT_TYPE.CARD)}
                      >
                        <a
                          className="nav-link active"
                          href="javascript:void(0);"
                        >
                          <span className="credit_card ">Credit Card</span>
                        </a>
                      </li>
                      {showWallet === PAYMENT_TYPE.GOOGLE_PAY ? (
                        <li
                          className={`nav-item icons ${
                            paymentType === PAYMENT_TYPE.GOOGLE_PAY
                              ? "active"
                              : ""
                          }`}
                          onClick={() => toggleTab(PAYMENT_TYPE.GOOGLE_PAY)}
                        >
                          <a className="nav-link" href="javascript:void(0);">
                            <span className="wallet_pay gpay_cls">
                              <img
                                alt="GPayIcon"
                                className="gpayBtn"
                                src={URLS.GOOGLE_PAY_MARK}
                              />
                            </span>
                          </a>
                        </li>
                      ) : null}
                      {showWallet === PAYMENT_TYPE.APPLE_PAY ? (
                        <li
                          className={`nav-item icons ${
                            paymentType === PAYMENT_TYPE.APPLE_PAY
                              ? "active"
                              : ""
                          }`}
                          onClick={() => toggleTab(PAYMENT_TYPE.APPLE_PAY)}
                        >
                          <a className="nav-link" href="javascript:void(0);">
                            <span className="applePay_cls">
                              <img
                                src={URLS.APPLE_PAY_ICON}
                                alt="ApplePayIcon"
                              />{" "}
                              Apple Pay
                            </span>
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className={`${paymentType === PAYMENT_TYPE.CARD?'d-block d-lg-flex':''}`}>
              {paymentType === PAYMENT_TYPE.CLICK_TO_PAY ? (
                  <Click2pay userSessionId={orderResponse?.userSessionId} amount={totalAmount} click2pay={click2payfunc} />
              ) : null}
              <div className={`col-12 ${paymentType === PAYMENT_TYPE.CARD?'col-md-6':''}`}>
                {paymentType === PAYMENT_TYPE.CARD ? (
                  <CreditCard ref={cardRef} fromPage="giftshop" />
                ) : null}
              </div>
              <div className={`col-12 ${paymentType === PAYMENT_TYPE.CARD?'col-md-6 ml-40':''}`}>
                {showContent?<><div className="col-12 mt-4">
                  {paymentType === PAYMENT_TYPE.APPLE_PAY || paymentType === PAYMENT_TYPE.GOOGLE_PAY?<div className="col-12 col-md-6 mb-3">
                    <label className="form-label">Email Address</label>
                    <input type="text" defaultValue={emailAddress} onKeyUp={({ target: { value } }: any) =>setEmailAddress(value)}/>
                    {errorEmailAddress?<div className="error_message">{errorEmailAddress}</div>:null}
                  </div>: null}
                  <div className="custom_checkbox option_link">
                    <input
                      type="checkbox"
                      value="1"
                      checked={isTermsChecked}
                      onClick={onCheckTerms}
                    />
                    <div className="state p-primary">
                      <label className="form-label">
                        <div>
                          I have read and understand the{" "}
                          {countryId === COUNTRY.STA
                            ? "State Cinema":
                            countryId === COUNTRY.ANG
                            ?"Angelika":
                            "Reading"}{" "}
                          Internet Ticket Sales{" "}
                          <span className="non_atag_link_u" onClick={openTerms}>
                            Terms and conditions
                          </span>{" "}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12  col-md-12">
                    <label className="form-label">Verification Code* </label>
                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey={`${CAPTCHA_SITE_KEY}`}
                        onChange={onChangeCaptcha}
                      />
                    </div>
                  </div>
                </div></>:null}

                <div className="payform-total">
                  <label>TOTAL PURCHASE</label>
                  <h3>${calTotal(cart, countryId).total.toFixed(2)}</h3>
                  {cart.map((v: ICartData) => (
                    <p>
                      {v.quantity} X 
                      {/* {v.isVariablePriceItem ? v.giftamount : v.price}{" "} */}
                      {v.description}: $
                      {(
                        v.quantity *
                        (v.isVariablePriceItem ? v.giftamount : v.price)
                      ).toFixed(2)}
                    </p>
                  ))}
                  {cartData.deliveryQty > 0 && cartData.deliveryFee > 0 ? (
                    <p>
                      {cartData.deliveryQty} X 
                      DELIVERY FEE: ${cartData.deliveryFee.toFixed(2)}
                    </p>
                  ) : null}
                </div>
              </div>
              </div>
              {errorMessage ? (
                <div className="error_message text-center">
                  <span>{errorMessage}</span>
                </div>
              ) : null}
              {showContent?<div className="col-md-12 movie_footer_btn_el">
                <div className="movie_button_wrap">
                  <button
                    type="button"
                    className="btn gray_btn"
                    onClick={onCancelTicket}
                  >
                    BACK
                  </button>
                  {paymentType === PAYMENT_TYPE.APPLE_PAY &&
                  countryId !== COUNTRY.NZ ? (
                    <ApplePay
                      processPayment={processPayment}
                      isValid={isValid}
                      onError={onError}
                      checkValid={checkValid}
                      page={'giftcard'}
                    />
                  ) : paymentType === PAYMENT_TYPE.GOOGLE_PAY &&
                    countryId !== COUNTRY.NZ ? (
                    <GPay
                      processPayment={processPayment}
                      isValid={isValid}
                      onError={onError}
                      checkValid={checkValid}
                      page={'giftcard'}
                    />
                  ) : (paymentType === PAYMENT_TYPE.APPLE_PAY ||
                      paymentType === PAYMENT_TYPE.GOOGLE_PAY) &&
                    countryId === COUNTRY.NZ ? (
                    <StripePayment
                      processPayment={processPayment}
                      isValid={isValid}
                      onError={onError}
                      checkValid={checkValid}
                    />
                  ) : (
                    <button
                      type="button"
                      className="btn black_btn"
                      onClick={onPurchase}
                    >
                      PURCHASE
                    </button>
                  )}
                </div>
              </div> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftshopPayment;
